import React, { FC } from 'react';

import ProductCard from 'components/cards/ProductCard';

import { IPropsProductsList } from './models';
import { useEffect } from 'react';

import './ProductsList.scss';

const ProductsList: FC<IPropsProductsList> = ({ products, isSmallDevice }) => {

  useEffect(() => {
    let intervalCounter = 0;
    const dataLayerCheckInterval = setInterval(() => {
      if(intervalCounter > 20){
        clearInterval(dataLayerCheckInterval);
      }
        // @ts-ignore
        if(!!window.dataLayer) {
          // @ts-ignore
          const items = products?.map((product: UmbracoProductCard.IStructure, index) => {
            return {
              'item_name' : `${product.titleMain}`,
              'currency' : 'USD',
              'item_brand' : 'BIOFREEZE',
            }
          })
          const eventsArray = {
            "event" :"view_item_list",
            "item_list_name" : 'Products collection',
            'ecommerce': {
              items
            }
          };
          // @ts-ignore)
          window.dataLayer?.push(
            eventsArray
          );
          intervalCounter = 0;
          clearInterval(dataLayerCheckInterval);
        }
        intervalCounter = intervalCounter + 1;
    }, 300);
        return () => {
          intervalCounter = 0;
        }
    }, []); 

  return <div data-testid="ProductsList" className="products-list" id="products_collection">
    {products?.map((item) => (
      <ProductCard key={item.url} data={item} variant={isSmallDevice ? 'vertical' : 'default'} />
    ))}
  </div>
}

export default ProductsList;
