import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import Typography from 'components/elements/Typography';
import DynamicImage from 'components/helpers/DynamicImage';
import ProductFeatures from 'components/product/ProductFeatures';
import { removeParentHtmlTag } from 'utils/stringUtils/stringUtils';

import { IPropsProductCard } from './models';
import './ProductCard.scss';
import Button from 'components/elements/Button';

const ProductCard: FC<IPropsProductCard> = ({ data, variant = 'default', cartctabutton, gridbuyMikMak, grideanvalue }) => {
  const { previewData, productFeatures, url, titleMain, defaultProductImage } = data;
  
  const customEvents = () => {
    if(typeof window !== 'undefined'){
      // @ts-ignore
      window.dataLayer?.push({
        "event" :"select_item",
        "item_list_name" : "Products",
        'ecommerce': {
          'items' : [{
            'item_name' : `${titleMain}`,
            'currency' : 'USD',
            'item_brand' : 'BIOFREEZE'
          }]
        }
      })
    }
  }

  if (variant === 'vertical') {
    return (
      <article
        data-testid="ProductCard"
        className={classNames('product-card', `product-card--variant-${variant}`)}
      >
        <Link to={url} className="product-card__link" aria-label={titleMain} onClick={customEvents}>
          <div className="product-card__content">
            <div className="product-card__image">
              {defaultProductImage && !previewData?.[0]?.previewImage?.desktopImage?.fallbackUrl ? (
                <DynamicImage
                  image={defaultProductImage}
                  objectFit="contain"
                  customAlt={
                    previewData?.[0]?.title?.[0]?.text
                      ? removeParentHtmlTag(previewData[0].title[0].text)
                      : titleMain
                  }
                />
              ) : null}
              {previewData?.[0]?.previewImage?.desktopImage?.fallbackUrl ? (
                <DynamicImage image={previewData[0].previewImage} objectFit="contain" />
              ) : null}
            </div>
            <ProductFeatures data={productFeatures} />
            {cartctabutton?.length ? (
              <div className="popular_Products__cta">
                <Button data={cartctabutton} />
              </div>
            ) : null}
            {process.env.GATSBY_MIKMAK_APP_ID && grideanvalue && gridbuyMikMak?.length ? (
              <div className="popular_Products__cta">
                <Button data={gridbuyMikMak} className="swn-tag-wtb-btn" ean={grideanvalue.ean} />
              </div>
            ) : null}   
          </div>

          {previewData?.[0]?.title.length ? (
            <Typography customClass="product-card__title" data={previewData[0].title} />
          ) : null}

          {titleMain && !previewData?.[0]?.title.length ? (
            <Typography
              customClass="product-card__title"
              customColor="black"
              data={[
                {
                  text: titleMain,
                  removeParentTag: true,
                  alignment: 'left',
                  tag: 'p',
                },
              ]}
            />
          ) : null}
        </Link>
      </article>
    );
  }

  return (
    <article
      data-testid="ProductCard"
      className={classNames('product-card', `product-card--variant-${variant}`)}
    >
      <Link to={url} className="product-card__link" aria-label={titleMain} onClick={customEvents}>
        <div className="product-card__image">
          {defaultProductImage && !previewData?.[0]?.previewImage?.desktopImage?.fallbackUrl ? (
            <DynamicImage
              image={defaultProductImage}
              objectFit="contain"
              customAlt={
                previewData?.[0]?.title?.[0]?.text
                  ? removeParentHtmlTag(previewData[0].title[0].text)
                  : titleMain
              }
            />
          ) : null}
          {previewData?.[0]?.previewImage?.desktopImage?.fallbackUrl ? (
            <DynamicImage image={previewData[0].previewImage} objectFit="contain" />
          ) : null}
        </div>
        <div className="product-card__content">
          {previewData?.[0]?.title.length ? (
            <Typography customClass="product-card__title" data={previewData[0].title} />
          ) : null}

          {titleMain && !previewData?.[0]?.title.length ? (
            <Typography
              customClass="product-card__title"
              customColor="black"
              data={[
                {
                  text: titleMain,
                  removeParentTag: true,
                  alignment: 'left',
                  tag: 'p',
                },
              ]}
            />
          ) : null}
          <ProductFeatures data={productFeatures} />
          {cartctabutton?.length ? (
            <div className="popular_Products__cta">
              <Button data={cartctabutton} />
            </div>
          ) : null}
          {process.env.GATSBY_MIKMAK_APP_ID && grideanvalue && gridbuyMikMak?.length ? (
            <div className="popular_Products__cta">
              <Button data={gridbuyMikMak} className="swn-tag-wtb-btn" ean={grideanvalue.ean} />
            </div>
          ) : null}
        </div>
      </Link>
    </article>
  );
};
export default ProductCard;
